import { useRouter } from "next/router";
import LazyLoad from "react-lazyload";
//import Breadcrumbs from "nextjs-breadcrumbs";
import SellHeader from "./SellHeader";
import FeaturedIn from "./FeaturedIn";
import PartnerLogo from "./PartnerLogo";
import Awards from "./Awards";
import Footer from "./Footer";
import { Container } from "react-bootstrap";

const s3_front_end_image =
  "https://budlisell.s3.ap-south-1.amazonaws.com/assets/themes/forntend/default/img/";
function InnerLayout(props) {
  const router = useRouter(); 
  return (
    <div className="wrapper">
      
      <SellHeader innerPage={props.innerPage} deviceType={props.deviceType} />
        
      
      {/* <Container fluid>
        {router.route !== "/sell" &&
        router.route !== "/accept-price/[[...slug]]" ? (
          <Breadcrumbs rootLabel="Home" listClassName="brdcrumbs" />
        ) : (
          ""
        )}
      </Container> */}
      {props.children}
      {/* <LazyLoad offset={100} once>
        <FeaturedIn data={{ s3_url: s3_front_end_image }} />
      </LazyLoad>
      <LazyLoad offset={100} once>
        <PartnerLogo data={{ s3_url: s3_front_end_image }} />
      </LazyLoad>
      <LazyLoad offset={100} once>
        <Awards data={{ s3_url: s3_front_end_image }} />
      </LazyLoad> */}
      <section className="pb-lg-5 pb-4">
        <div className="container">
          <h2 className="mb-3 h4 fw-600 text-center">In The Spotlight: See Where We've Been Featured</h2>
          <LazyLoad offset={100} once>
            <FeaturedIn data={{ s3_url: s3_front_end_image }} />
          </LazyLoad>
        </div>
      </section>
      <section className="pb-lg-5 pb-4">
        <div className="container">
          <h2 className="mb-3 h4 fw-600 text-center col-lg-5 p-0 mx-auto">Recognition: Awards we've received for our service and commitment</h2>
          <LazyLoad offset={100} once>
            <Awards data={{ s3_url: s3_front_end_image }} />
          </LazyLoad>
        </div>
      </section>
      <Footer data={{ s3_url: s3_front_end_image }} />
    </div>
  );
}
export default InnerLayout;
