import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

class PartnerLogo extends Component {
  render() {
    return (
      <Container fluid>
        <div className="featured_bg">
          <div align="center">
            <h2>Partners</h2>
          </div>
        </div>
        <div className="logos">
          <Carousel
            slidesPerPage={5}
            infinite
            autoPlay={3000}
            stopAutoPlayOnHover={true}
            breakpoints={{
              640: {
                slidesPerPage: 1,
                arrows: false,
              },
              900: {
                slidesPerPage: 3,
                arrows: false,
              },
              1024: {
                slidesPerPage: 4,
                arrows: false,
              },
            }}
          >
            <a
              href="https://www.amazon.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${this.props.data.s3_url}amazon_logo.png`}
                className="lazyload"
                alt="Amazon"
              />
            </a>
            <a
              href="https://www.flipkart.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${this.props.data.s3_url}flipkart_logo.png`}
                className="lazyload"
                alt="Flipkart"
              />
            </a>
            <a
              href="https://www.cry.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${this.props.data.s3_url}cry_logo.jpg`}
                className="lazyload"
                alt="CRY"
              />
            </a>
            <a
              href="https://paytm.com/"
              title="Paytm"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${this.props.data.s3_url}paytm_logo.jpg`}
                className="lazyload"
                alt="Paytm"
              />
            </a>
            <a
              href="https://www.giveindia.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={`${this.props.data.s3_url}giveindia_logo.jpg`}
                className="lazyload"
                alt="GiveIndia"
              />
            </a>
          </Carousel>
        </div>
      </Container>
    );
  }
}

export default PartnerLogo;
